import React from 'react';
import { Flex, Stack, Text, Link } from '@chakra-ui/react';
import Logo from '../ui/Logo';

const Footer = (props: any) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={8}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      // color={["white", "white", "primary.700", "primary.700"]}
      {...props}
    >
      <Stack spacing={{ base: '4', md: '5' }}>
        <Logo />
        <Link
          fontSize="sm"
          color="subtle"
          href='https://six-zephyr-764.notion.site/Spotlight-for-Notion-Documentation-941bc1ff5dba4f52b2d89bc4dab79940'
        >Documentation</Link>
        <Link fontSize="sm" color="subtle" href='/privacy'>Privacy Policy</Link>
        <Link fontSize="sm" color="subtle" href='/terms'>Terms of Service</Link>
        {/* <Link fontSize="sm" color="subtle" href='mailto: spotlightfornotion@gmail.com'>Contact Us</Link> */}
        <Text fontSize="sm" color="subtle">
          &copy; {new Date().getFullYear()} Spotlight for Notion. All rights reserved.
        </Text>
      </Stack>
    </Flex>
  )
};

export default Footer;
