import React from "react";
import { Box, Stack, Heading, Text, OrderedList, ListItem, UnorderedList, Link } from '@chakra-ui/react';

import LandingLayout from "../components/layouts/LandingLayout";

export default function Terms() {
  return (
    <LandingLayout>
      <Box p={6}>
        <Stack spacing={3}>
          <Heading as="h1" size="2xl">Terms of Service</Heading>
          <Text fontSize="sm" fontStyle="italic">Last updated: 1st November 2022</Text>
          <Text>When we say “we”, “our”, “us” in this document, we are referring to Spotlight for Notion.</Text>
          <Text>When we say “Service” or “App”, we are referring to Spotlight for Notion.</Text>
          <Text>When you use our Service, now or in the future, you are agreeing to the latest Terms of Service. That's true for all features that we add to our Service over time. There may be times where we do not exercise or enforce any right or provision of the Terms of Service; in doing so, we are not waiving that right or provision.These terms do contain a limitation of our liability.</Text>
          <Text>If you violate any of the terms, we may terminate your access to this Service.</Text>
          <Heading as="h2" size="md">Uptime, Security, and Privacy</Heading>
          <Text>
            <OrderedList>
              <ListItem>Your use of the Service is at your sole risk. We provide this Service on an “as is” and “as available” basis. We do not offer service-level agreements for this Service but we do take uptime of this application seriously.</ListItem>
              <ListItem>We take many measures to protect and secure your data through backups, redundancies, and encryption. We enforce encryption for data transmissions from the public Internet. There are some edge cases where we may send your data through our network unencrypted.</ListItem>
              <ListItem>When you use our Service, you entrust us with your data. You agree that we may process your data as described in our Privacy Policy and for no other purpose. We can access your data for the following reasons:</ListItem>
              <Text>
                <UnorderedList>
                  <ListItem>To help you with support requests you make.</ListItem>
                  <ListItem>To safeguard Spotlight for Notion. We will look at logs and metadata as part of our work to ensure the security of your data and the Service as a whole.</ListItem>
                </UnorderedList>
              </Text>
              <ListItem>We use third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service. We use third party subprocessors, such as cloud computing providers to run our service. We establish GDPR-compliant data processing agreements with each subprocessors.</ListItem>
              <ListItem>The following is a list of personal data subprocessors we use:</ListItem>
              <Text>
                <UnorderedList>
                  <ListItem><Link href="https://aws.amazon.com/">Amazon Web Service</Link>. Cloud Service Provider.</ListItem>
                </UnorderedList>
              </Text>
              <ListItem>As we are a Notion developer, we adhere to Notion's <Link href="https://www.notion.so/Developer-Terms-ba4131408d0844e08330da2cbb225c20">Developer Agreement</Link> and <Link href="https://www.notion.so/Privacy-Policy-3468d120cf614d4c9014c09f6adc9091">Privacy Policy</Link> as well.</ListItem>
            </OrderedList>
          </Text>
          <Heading as="h2" size="md">Copyright and Content Ownership</Heading>
          <Text>
            <OrderedList>
              <ListItem>The names, look, and feel of the Service are copyright© to us. All rights reserved. You may not duplicate, copy or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written permission from us.</ListItem>
              <ListItem>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Services without express written permission by us.</ListItem>
              <ListItem>You must not modify another website so as to falsely imply that it is associated with the Service or us.</ListItem>
            </OrderedList>
          </Text>
          <Heading as="h2" size="md">Features and Bugs</Heading>
          <Text>We design our Service with care, based on our own experience and the experiences of users who share their time and feedback. However, there is no such thing as a service that pleases everybody. We make no guarantees that our Service will meet your specific requirements or expectations.</Text>
          <Text>We also test all of our features extensively before shipping them. As with any software, our Service inevitably have some bugs. We track the bugs reported to us and work through priority ones, especially any related to security or privacy. Not all reported bugs will get fixed and we don't guarantee completely error-free Services.</Text>
          <Heading as="h2" size="md">Liability</Heading>
          <Text>We mention liability throughout these Terms but to put it all in one section:</Text>
          <Text>You expressly understand and agree that we shall not be liable, in law or in equity, to you or to any third party for any direct, indirect, incidental, lost profits, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if we have been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to this Terms of Service or the Services, whether as a breach of contract, tort (including negligence whether active or passive), or any other theory of liability.</Text>
        </Stack>
      </Box>
    </LandingLayout>
  );
}
