import React from 'react';
import { Divider, Flex } from '@chakra-ui/react';
import Footer from '../sections/Footer';
import Header from '../sections/Header';

export default function LandingLayout(props: any) {
  return (
    <Flex
      direction="column"
      align="center"
      maxW={{ xl: "1200px" }}
      m="0 auto"
      {...props}
    >
      <Header />
      {props.children}
      <Divider />
      <Footer />
    </Flex>
  );
}
