import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";
import chart from "../sample-chart.png";

export default function Landing() {
  return (
    <LandingLayout>
      <Hero
        title="Gain insight to your workflow."
        subtitle="You want more visibility on how is your Notion workflow doing. Shine a spotlight on your workflow. Control charts can help."
        image={chart}
      />
    </LandingLayout>
  );
}
