import React from "react";
import { Box, Stack, Heading, Text, Link } from '@chakra-ui/react';

import LandingLayout from "../components/layouts/LandingLayout";

export default function Privacy() {
  return (
    <LandingLayout>
      <Box p={6}>
        <Stack spacing={3}>
          <Heading as="h1" size="2xl">Privacy Policy</Heading>
          <Text fontSize="sm" fontStyle="italic">Last updated: 1st November 2022</Text>
          <Text>
            The privacy of your data is a big deal to us. In this policy, we lay out: what data we collect and why; how your data is handled; and your rights to your data. We promise we will never sell your data.
          </Text>
          <Text>This policy applies to the service provided on this site.</Text>
          <Heading as="h2" size="md">What we collect and why</Heading>
          <Text>This policy applies to the service provided on this site.</Text>
          <Heading as="h2" size="md">Identity & Access</Heading>
          <Text>As a Notion app, we collect only the necessary information needed for our app to work with your Notion account, this includes API related information for us to perform API calls to Notion and also limited content from Notion pages. We do not store page contents.</Text>
          <Heading as="h2" size="md">Voluntary Correspondence</Heading>
          <Text>When you write to us with a question or ask for help, we keep that correspondence, including email address, so that we have a history of past correspondences to reference if you reach out in the future. We also store any information you volunteer like surveys. Sometimes when we do user interviews, we may ask for your permission to record the conversation for future reference or use. We only do so if you give your express consent.</Text>
          <Heading as="h2" size="md">Information we do not collect</Heading>
          <Text>We don't collect any characteristics of protected classifications including age, race, gender, religion, sexual orientation, gender identity, gender expression, or physical and mental abilities or disabilities.You may provide these data voluntarily, such as if you include a pronoun preference in your communication when writing to us.</Text>
          <Heading as="h2" size="md">When we access or share your information</Heading>
          <Text>Our default practise is to not access your information. The only times we will ever access or share your information are:</Text>
          <Text fontWeight={800}>To provide products or services you've requested.</Text>
          <Text>We do use some third-party services to run our applications and only to the extent necessary process some or all of your personal information via these third parties.</Text>
          <Text fontWeight={800}>To help you troubleshoot or fix a software bug, with your permission.</Text>
          <Text>If at any point we need to access your Notion account to investigate an issue, we will ask for your consent before proceeding.</Text>
          <Heading as="h2" size="md">How we secure your data</Heading>
          <Text>All data is encrypted via SSL/TLS when transmitted from our servers to your browser.</Text>
          <Text>For this service, most data are not encrypted while they live in our database (since it needs to be ready to send to you when you need it),but we go to great lengths to secure your data at rest.</Text>
          <Text>If at any point we need to access your Notion account to investigate an issue, we will ask for your consent before proceeding.</Text>
          <Heading as="h2" size="md">What happens when you uninstall this app</Heading>
          <Text>In this Notion app, we store limited content from your Notion instance, for us to keep track of how long a page is in a column. We do not store page contents. When you uninstall this Notion app, all information is deleted immediately without prejudice. We also have some backups of our application databases, which are kept for up to another 35 days.</Text>
          <Text>If at any point we need to access your Notion account to investigate an issue, we will ask for your consent before proceeding.</Text>
          <Heading as="h2" size="md">Location of site and data</Heading>
          <Text>Our products and other web properties are operated in the United States. If you are located in the European Union or elsewhere outside of the United States, please be aware that any information you provide to us will be transferred to and stored in the United States. By using our Site, participating in this service and/or providing us with your information, you consent to this transfer.</Text>
          {/* <Text>If you have a question about any of the Privacy Policy, please <Link href='mailto: spotlightfornotion@gmail.com'>contact us</Link>.</Text> */}
        </Stack>
      </Box>
    </LandingLayout>
  );
}
